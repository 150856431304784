<template>
  <page-view class="props-make" :up-class="'props-make'">
    <banner :bannerList="bannerList" :textList="textList" :bannerImg="bannerImg"></banner>

    <div class="texts">
      <div>全国展示道具行业厂家优选</div>
      <div>
        按品类划分优选展示道具厂家，质量可控。<br/>
        按品牌定位划分优选展示道具厂家，性价比高。<br/>
        按区域划分就近优选展示道具厂家，减少中间环节。
      </div>
    </div>

    <div class="twobanner">
      <el-carousel style="height: 100% !important;" trigger="click" arrow="always" :interval="2000" ref="carouselRef"
        @change="changeBannerAuto">
        <el-carousel-item style="height: 100% !important;" v-for="(item, index) in twobannerList" :key="index">
          <!-- <img src="../../assets/img/2.jpg" width="100%" height="100%" /> -->
          <img v-lazy="item.image" width="100%" height="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="product-show">
      <div class="tltle">道具工厂展示</div>

      <div class="look-more">
        <div class="btn" @click="$router.push({ name: 'DesignComAll', params: { companyType: 4 } })">查看更多</div>
      </div>

      <div class="product-show-content">
        <div class="product-item" v-for="item in comList" :key="item.id" @click="toComIndex(item.syscompanyid)">
          <div class="item-name">{{ item.name }}</div>
          <div class="item-name">{{ item.addressDetail || '' }}</div>
          <div><img :src="item.cmsArticle2[0]?.image || item.cmsArticle1[0]?.image || item.cmsArticle4[0]?.image || item.logo" height="100%" width="100%" /></div>
        </div>
      </div>
      <div style="text-align: center;color: #a5a3a3;">百川智慧商展产业数字化平台</div>
    </div>
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import { mapActions } from "vuex";
import Banner from "@/components/provideMater/Banner.vue";
export default {
  name: "PropsMake",
  components: { Banner },
  data() {
    return {
      isShowTo: -1,
      bannerList: [],
      twobannerList: [],
      textList: ['珠宝类', '化妆品', '钟表类', '鞋服类', '餐饮类', '超市类', '数码类', '家电类', '运动类', '新能源类'],
      comList: [],
      excellentList: [],
      successList: [],
      newsList: [],
      move: false,
      bannerImg: require('../../assets/img/finddao.jpg')
    };
  },
  created() {
    this.getImg();
    this.getImgs();
    this.getListProduct();
    this.getexCellent();
  },
  methods: {
    ...mapActions(["setTheArticle", "setTheArticleList", "setCaseMore"]),
    async getImg() {
      let result = await this.$api.getBanner('PT00011');
      this.bannerList = result.itemList;
    },

    async getImgs() {
      let result = await this.$api.getBanner('PT00718');
      this.twobannerList = result.itemList;
    },
    changeBanner(index) {
      this.$refs.carouselRef.setActiveItem(index);
      this.bannerIndex = index;
    },
    changeBannerAuto(index) {
      this.bannerIndex = index;
    },
    //获取产品展示
    async getListProduct() {
      let result = await this.$api.selectSn({
        bc_company_type: 4,
        current: 1,
        size: 6,
        s3:0//是优质企业
      });
      this.comList = result.data;
    },

    //获取优秀企业
    async getexCellent() {
      let result = await this.$api.selectSn({
        bc_company_type: 4,
        current: 1,
        s3: 0,
      });
      this.excellentList = result.data;
    },

    toComIndex(syscompanyid) {
      const openRoute = this.$router.resolve({
        name: "ComIndex",
        params: { syscompanyid },
      });
      window.open(openRoute.href, "_blank");
    },
  },
};
</script>
<style lang='scss' scoped>
.props-make {
  .texts {
    font-size: 2.6vw;
    color: rgb(41, 41, 41);
    font-weight: 600;
    width: 80%;
    margin: auto;
    padding: 5% 0%;

    >div:nth-child(2) {
      margin-top: 2%;
      font-weight: 400;
      font-size: 1.5vw;
      line-height: 2;
    }
  }

  .twobanner {
    height: 30vw;

    :deep(.el-carousel__container) {
      height: 100% !important;
    }
  }

  .product-show {
    padding: 5% 0% 4%;
    background-color: #F6F6F6;
    overflow: hidden;

    .tltle {
      font-size: max(16px,3.5vw);
      text-align: center;
    }

    .look-more {
      margin: 0 auto;
      color: #4C9C51;
      text-align: center;

      .btn {
        font-size: 12px;
        padding: 10px 0px;
        cursor: pointer;
      }
    }

    .product-show-content {
      width: 90%;
      margin: 3% auto;
      font-size: 1vw;
      color: #666666;
      white-space: nowrap;
      animation: liner 20s infinite linear;

      @keyframes liner {
        0% {
          transform: translateX(0%);
        }

        100% {
          transform: translateX(-90%);
        }
      }

      &:hover {
        animation-play-state: paused;
        -webkit-animation-play-state: paused;
      }

      .product-item {
        background: #ffffff;
        width: max(150px,30%);
        height: max(100px,20vw);
        padding-top: 2%;
        border-radius: 1vw;
        overflow: hidden;
        display: inline-flex;
        flex-direction: column;
        margin-right: 3%;
        text-align: center;
        cursor: pointer;
        .item-name{
          width: 90%;
          margin: auto;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        >div {
          padding: 1% 0%;
        }

        >div:nth-child(1) {
          font-weight: bold;
        }

        >div:nth-child(2) {
          padding-bottom: 6%;
          font-size: 0.9vw;
        }

        >div:nth-child(3) {
          flex: 1;
          overflow: hidden;
        }
      }
    }
  }
}</style>